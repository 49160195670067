@font-face {
  font-family: 'GE Inspira';
  src: url('./GEInspRg.woff') format('woff'), url('./GEInspRg.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GE Inspira';
  src: url('./GEInspIt.woff') format('woff'), url('./GEInspIt.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'GE Inspira';
  src: url('./GEInspMd.woff') format('woff'), url('./GEInspMd.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GE Inspira';
  src: url('./GEInspMdIt.woff') format('woff'), url('./GEInspMdIt.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'GE Inspira';
  src: url('./GEInspBd.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'GE Inspira';
  src: url('./GEInspBdit.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./MuseoSans-100.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./MuseoSans-100Italic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./MuseoSans-300.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./MuseoSans-300Italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./MuseoSans-500.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./MuseoSans-500Italic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./MuseoSans-700.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./MuseoSans-700Italic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./MuseoSans-900.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./MuseoSans-900Italic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}
